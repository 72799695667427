<template>
  <div>
    <div :style="{
      backgroundImage: 'url(' + getback(item?.themeImg) + ')',
      margin: '0.5rem 1rem 0.5rem 1rem',
      with: '100%',
      height: '12rem',
      borderRadius: '1rem',
      backgroundSize: 'cover'
    }">
      <div class="flex" style="flex-direction: column; justify-content: space-between; height: 100%">
        <div style="display: flex; justify-content: space-between; width: 100%">
          <div class="cardNo">卡号:<span style="font-size: 0.85rem;">{{ item?.cardNo }}</span> </div>
          <div v-if="activity == '1'" style="margin: 1.2rem 1.2rem 0 0; font-size: 0.85rem; color: #fdfafa">
            {{ $moment(item?.expireDate).format("YYYY.MM.DD") }} <span style="font-size: 0.9rem;">到期</span>
          </div>
        </div>

        <div class="width-100 flex jcsb">
          <div style="font-size: 1.1rem; color: #fdfafa; margin-left: 1.2rem">
            <p style="color: white; margin-bottom: 0.2rem">
              {{ getCardName(item?.cardShowName ? item?.cardShowName : item?.cardName) }}
            </p>
          </div>

          <div v-if="ticketType == 'E3'" style="text-align: end; color: #ffffff; margin-right: 1.2rem; font-size: 1.1rem">
            {{ item?.cardRemainingPoint }}票
          </div>
          <div v-else style="text-align: end; color: #ffffff; margin-right: 1.2rem; font-size: 1.1rem">
            {{ item?.cardRemainingPoint }}点
          </div>
        </div>
        <div class="width-100 flex jcsb" style="margin-bottom: 1.3rem; margin-right: 1.2rem;">
          <div v-if="activity == '1' && ticketType == 'E4' && item?.upgradeFlag == '1'" class="updateTag">
            升级版
          </div>
          <div v-else></div>

          <div class="flex">
            <div v-if="activity == '1' && item?.ifUpgrade == '1' && item?.upgradeFlag == '0'" :class="getColorType(item?.themeImg, index) == 1 ? 'bottomTag' : 'bottomTag2'" @click.stop="upgrade">
              升级
            </div>
            <span v-if="activity == '1'" :class="getColorType(item?.themeImg, index) == 1 ? 'bottomTag' : 'bottomTag2'" @click="goUse()">去使用
            </span>
            <div v-else style="height: 1.2rem"></div>
          </div>

        </div>
      </div>
    </div>
    <div style="  border-bottom: 1px solid #DDD; margin-top: 1rem;"> </div>
    <div class="itemBack" @click="userDetail">
      <div class="flex a-i-c"> <van-icon name="credit-pay" size="1.5rem" /> <span class="itemtext"> 消费记录 </span></div>
      <van-icon name="arrow" color="#999" size="1.1rem" />
    </div>
    <div v-if="activity == '1'" class="itemBack" @click="goUse">
      <div class="flex a-i-c"><van-icon name="cart-o" size="1.5rem" /><span class="itemtext"> 去使用 </span> </div>
      <van-icon name="arrow" color="#999" size="1.1rem" />

    </div>
    <div v-if="activity == '1' && item?.ifUpgrade == '1' && item?.upgradeFlag == '0'" class="itemBack" @click="upgrade">
      <div class="flex a-i-c"><van-icon name="upgrade" size="1.5rem" /><span class="itemtext"> 升级 </span> </div>
      <van-icon name="arrow" color="#999" size="1.1rem" />

    </div>
  </div>
</template>

<script>
import { cacheCardInfo } from './index.action'
export default {
  name: '',
  data () {
    return {
      item: null,
      ticketType: '',
      index: 0,
      imageback: require("@/assets/images/card_bg.svg"),
      imageback2: require("@/assets/images/card_bg2.svg"),
      imageErrorback: require("@/assets/images/card_error_bg.svg"),
      activity: this.$route.query.activity
    }
  },
  created () { },
  components: {},
  methods: {

    upgrade () {
      localStorage.setItem("cardItem", JSON.stringify(this.item));
      this.$router.push({
        path: "/UpdateCard",
        query: {
          ticketType: localStorage.getItem('entrCode')
        }
      });
    },
    getCardName (str) {
      return str ? str.length > 10 ? str.substring(0, 10) : str : ''
    },
    getback (str) {
      if (this.activity == "1") {
        if (str == "" || str == undefined) {
          if (this.index % 2 == 0) {
            return this.imageback;
          } else return this.imageback2;
        } else {
          return str;
        }
      } else {
        return this.imageErrorback;
      }
    },
    userDetail () {
      // this.$Toast('敬请期待')
      this.$router.push({
        path: "/UsedRecord",
        query: {
          cardId: this.item.cardId
        }
      });
    },
    getColorType (str, index) {
      if (str == "" || str == undefined) {
        if (index % 2 == 0) {
          return 1;
        } else return 2;
      } else {
        return 1;
      }
    },
    goUse () {
      // localStorage.setItem("cardId", this.item.cardId);
      cacheCardInfo(this.item);
      if (this.item.ifCakeCardEnum == "CAKE_CARD") {
        this.$router.push({
          path: "/CakaHome"
        });
      } else if (this.item.ifCakeCardEnum == "MOVIE_CARD") {
        this.$router.push({
          path: "/MoviesHome"
        });
      } else {
        const data = {
          bathId: this.item.id,
          upgradeFlag: this.item.upgradeFlag
        }
        this.$api
          .isHomeShow(data)
          .then((res) => {
            if (res.data === true) {
              this.$router.push({
                path: "/HomeNew",
                query: { cardShowFlag: this.item.cardShowFlag }
              });
            } else {
              this.$router.push({
                path: '/GoodsList',
                query: { activityId: this.item.id }
              })
            }
          })
          .catch((err) => {
            console.log('>>?')
            this.$Toast.fail("此卡不可用");
          });
        // if (this.item.homeShow == '1') {
        //   this.$router.push({
        //     path: "/Home",
        //     query: { cardShowFlag: this.item.cardShowFlag }
        //   });
        // } else {
        //   this.$router.push({
        //     path: '/GoodsList',
        //     query: { activityId: this.item.id }
        //   })
        // }

      }
    }
  },
  mounted () {
    this.item = JSON.parse(localStorage.getItem('itemDetail'))
    this.ticketType = localStorage.getItem('entrCode')
    this.index = this.$route.query.index
    this.activity = this.$route.query.activity
  },
}

</script>
<style  lang="scss" scoped>
.cardNo {
  color: #fff;
  margin: 1.2rem 0 0 1.2rem;
  font-size: 0.9rem;
}

.bottomTag {
  font-size: 0.9rem;
  color: #d59a54;
  margin-right: 0.5rem;
  width: 4rem;
  border-radius: 1rem;
  text-align: center;
  padding: 0.2rem;
  background-color: #fff;
}

.bottomTag2 {
  font-size: 0.9rem;
  color: #6354ff;
  margin-right: 0.5rem;
  width: 4rem;
  border-radius: 1rem;
  text-align: center;
  padding: 0.2rem;
  background-color: #fff;
}

.updateTag {
  font-size: 1rem;
  color: white;
  margin-left: 1.5rem;
}

.itemBack {
  border-bottom: 1px solid #ddd;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.itemtext {
  font-size: 1.1rem;
  color: #666;
  margin-left: 1rem;
}
</style>